import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppConfig } from '../../../app.config';
import { LoginService } from '../../login/login.service';
import { AppGuard } from '../../../app.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInventoryInterceptor } from '../interceptor/inventory-interceptor.interceptor';
import { InventoryAllocationComponent } from './inventory-allocation/inventory-allocation.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AutoCompleteStockComponent } from '../auto-complete/auto-complete-stock/auto-complete-stock.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../layout/shared.module';
import { NeoTableLibModule } from 'n-neo-table-lib';
import { NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';

const APP_PROVIDERS = [AppConfig, LoginService, AppGuard];
@NgModule({
  declarations: [InventoryAllocationComponent,AutoCompleteStockComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgbTabsetModule
  ],
  exports: [InventoryAllocationComponent],
  entryComponents: [InventoryAllocationComponent],
  // providers: [
  //   APP_PROVIDERS,
  //   {
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: AppInventoryInterceptor,
  //     multi: true, 
  //   }
  //   ]
})
export class InventoryModule { }
