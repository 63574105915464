import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable, Subject } from 'rxjs';
import { AppConfig } from '../../../../app.config';
import { ResponseData } from '../../../../core/modal/base-save-update-response';
import { SearchData } from '../../../../core/modal/search-data';
import { ItemStockModal } from '../modal/item-stock-modal';
import { StockAreaModal } from '../modal/stock-area-modal';




@Injectable({
  providedIn: 'root'
})
export class InventoryService{
  public getAllByPartner = '/getAllByPartner';
  config: any;
  private httpClient: HttpClient;
  private subject = new Subject<any>();

  constructor(private appconfig: AppConfig,private handler: HttpBackend) {
    this.config = appconfig.getConfig();
    this.httpClient = new HttpClient(handler);
   }

  returnCustId(): Observable<any> {
    return this.subject.asObservable();
  }

  saveInventoryStock(obj: any) {
    this.subject.next(obj);
   }


  getModuleName(){
    return 'inventory'
  }

  getAutoCompleteStock(searchData): Observable<ResponseData<ItemStockModal>>{
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.post<ResponseData<ItemStockModal>>(
      this.config.inventoryUrl + 'api/v1/chemical/itemStock/getFreeStockList',searchData, {headers:header}
    );
  }

  getStaffWiseInventory(searchData): Observable<ResponseData<ItemStockModal>>{
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.post<ResponseData<ItemStockModal>>(
      this.config.inventoryUrl + 'api/v1/chemical/itemStock/getStockList/v2',searchData, {headers:header}
    );
  }

  getStockList(body): Observable<ResponseData<ItemStockModal>>{
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.post<ResponseData<ItemStockModal>>(
      this.config.inventoryUrl + 'api/v1/chemical/itemStock/getStockByRange',body, {headers:header}
    );
  }

  getAllItem(): Observable<ResponseData<ItemStockModal>>{
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.get<ResponseData<ItemStockModal>>(
      this.config.inventoryUrl + 'api/v1/chemical/item/getAllItem',{headers:header}
    );
  }

  getProperty(id): Observable<ResponseData<ItemStockModal>>{
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.get<ResponseData<ItemStockModal>>(
      this.config.inventoryUrl + 'api/v1/chemical/item/getWithProperties/'+id,{headers:header}
    );
  }

  getStockListByRange(list): Observable<ResponseData<ItemStockModal>>{
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.post<ResponseData<ItemStockModal>>(
      this.config.inventoryUrl + 'api/v1/chemical/itemStock/getStockByRange',list, {headers:header}
    );
  }


  getCaseInventoryHistory(searchData): Observable<ResponseData<ItemStockModal>>{
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.get<ResponseData<ItemStockModal>>(
      this.config.inventoryUrl + 'api/v1/chemical/consumption/getMaterialAgainstCaseTicket/'+searchData, {headers:header}
    );
  }

  public onAllocateSeleced(reqObj): Observable<ResponseData<any>> {
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.post<ResponseData<any>>(this.config.inventoryUrl + 'api/v1/chemical/consumption/save',reqObj,  {headers:header});
  }

  setReservedStock(searchData): Observable<ResponseData<ItemStockModal>>{
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.post<ResponseData<ItemStockModal>>(
      this.config.inventoryUrl + 'api/v1/chemical/itemStock/changeReserveStatus',searchData, {headers:header}
    );
  }

  getStockAreaList(): Observable<ResponseData<StockAreaModal>> {
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.get<ResponseData<StockAreaModal>>(
      this.config.inventoryUrl + 'api/v1/chemical/stockArea' + this.getAllByPartner, {headers:header}
    );
  }

  getStock(){
    let header = new HttpHeaders().set(
      "Authorization",
       localStorage.getItem("inventoryToken")
    );
    return this.httpClient.get<ResponseData<ItemStockModal>>(
      this.config.inventoryUrl + 'api/v1/chemical/itemStock', {headers:header}
    );
  }

  dataList:any = [
    {
      "itemId": 12,
      "productCode": "Fiber Cable - Armoured",
      "itemName": "Fiber Cable - Armoured",
      "searchKey": null,
      "itemTupleId": 356,
      "itemProperties": {},
      "shortDescription": "FCC(2F) ,BRA(NA) ",
      "uom": "METER",
      "remainingQty": 6,
      "availableQty": 4,
      "stockArea": "Central Stock - Porvorim",
      "staffId": null,
      "isNative": true,
      "start": 6,
      "end": 9,
      "availableStockList": [
        {
          "stockId": 5656,
          "itemId": 12,
          "itemName": "Fiber Cable - Armoured",
          "productCode": "Fiber Cable - Armoured",
          "price": 14.5,
          "uom": "METER",
          "itemTupleId": 356,
          "shortDescription": "FCC(2F) ,BRA(NA) ",
          "stock": 1,
          "secondaryStock": 0,
          "partnerId": 1,
          "stockAreaId": 1,
          "stockAreaName": "Central Stock - Porvorim",
          "grnNumber": "GRN-JUL-2024-25",
          "isPackingItem": false,
          "weight": 1,
          "productTypes": "COMPONENT",
          "assetId": "Fiber Cable - Armoured-6",
          "staffName": " ",
          "isFaulty": false,
          "totalStock": 1
        },
        {
          "stockId": 5657,
          "itemId": 12,
          "itemName": "Fiber Cable - Armoured",
          "productCode": "Fiber Cable - Armoured",
          "price": 14.5,
          "uom": "METER",
          "itemTupleId": 356,
          "shortDescription": "FCC(2F) ,BRA(NA) ",
          "stock": 1,
          "secondaryStock": 0,
          "partnerId": 1,
          "stockAreaId": 1,
          "stockAreaName": "Central Stock - Porvorim",
          "grnNumber": "GRN-JUL-2024-25",
          "isPackingItem": false,
          "weight": 1,
          "productTypes": "COMPONENT",
          "assetId": "Fiber Cable - Armoured-7",
          "staffName": " ",
          "isFaulty": false,
          "totalStock": 2
        },
        {
          "stockId": 5658,
          "itemId": 12,
          "itemName": "Fiber Cable - Armoured",
          "productCode": "Fiber Cable - Armoured",
          "price": 14.5,
          "uom": "METER",
          "itemTupleId": 356,
          "shortDescription": "FCC(2F) ,BRA(NA) ",
          "stock": 1,
          "secondaryStock": 0,
          "partnerId": 1,
          "stockAreaId": 1,
          "stockAreaName": "Central Stock - Porvorim",
          "grnNumber": "GRN-JUL-2024-25",
          "isPackingItem": false,
          "weight": 1,
          "productTypes": "COMPONENT",
          "assetId": "Fiber Cable - Armoured-8",
          "staffName": " ",
          "isFaulty": false,
          "totalStock": 3
        },
        {
          "stockId": 5659,
          "itemId": 12,
          "itemName": "Fiber Cable - Armoured",
          "productCode": "Fiber Cable - Armoured",
          "price": 14.5,
          "uom": "METER",
          "itemTupleId": 356,
          "shortDescription": "FCC(2F) ,BRA(NA) ",
          "stock": 1,
          "secondaryStock": 0,
          "partnerId": 1,
          "stockAreaId": 1,
          "stockAreaName": "Central Stock - Porvorim",
          "grnNumber": "GRN-JUL-2024-25",
          "isPackingItem": false,
          "weight": 1,
          "productTypes": "COMPONENT",
          "assetId": "Fiber Cable - Armoured-9",
          "staffName": " ",
          "isFaulty": false,
          "totalStock": 4
        }
      ],
      "stockAreaId": 1
    },
    {
      "itemId": 12,
      "productCode": "Fiber Cable - Armoured",
      "itemName": "Fiber Cable - Armoured",
      "searchKey": null,
      "itemTupleId": 356,
      "itemProperties": {},
      "shortDescription": "FCC(2F) ,BRA(NA) ",
      "uom": "METER",
      "remainingQty": 6,
      "availableQty": 4,
      "stockArea": "Central Stock - Porvorim",
      "staffId": null,
      "isNative": true,
      "start": 6,
      "end": 9,
      "availableStockList": [
        {
          "stockId": 5660,
          "itemId": 12,
          "itemName": "Fiber Cable - Armoured",
          "productCode": "Fiber Cable - Armoured",
          "price": 14.5,
          "uom": "METER",
          "itemTupleId": 356,
          "shortDescription": "FCC(2F) ,BRA(NA) ",
          "stock": 1,
          "secondaryStock": 0,
          "partnerId": 1,
          "stockAreaId": 1,
          "stockAreaName": "Central Stock - Porvorim",
          "grnNumber": "GRN-JUL-2024-25",
          "isPackingItem": false,
          "weight": 1,
          "productTypes": "COMPONENT",
          "assetId": "Fiber Cable - Armoured-6",
          "staffName": " ",
          "isFaulty": false,
          "totalStock": 1
        },
        {
          "stockId": 5661,
          "itemId": 12,
          "itemName": "Fiber Cable - Armoured",
          "productCode": "Fiber Cable - Armoured",
          "price": 14.5,
          "uom": "METER",
          "itemTupleId": 356,
          "shortDescription": "FCC(2F) ,BRA(NA) ",
          "stock": 1,
          "secondaryStock": 0,
          "partnerId": 1,
          "stockAreaId": 1,
          "stockAreaName": "Central Stock - Porvorim",
          "grnNumber": "GRN-JUL-2024-25",
          "isPackingItem": false,
          "weight": 1,
          "productTypes": "COMPONENT",
          "assetId": "Fiber Cable - Armoured-7",
          "staffName": " ",
          "isFaulty": false,
          "totalStock": 2
        },
        {
          "stockId": 5662,
          "itemId": 12,
          "itemName": "Fiber Cable - Armoured",
          "productCode": "Fiber Cable - Armoured",
          "price": 14.5,
          "uom": "METER",
          "itemTupleId": 356,
          "shortDescription": "FCC(2F) ,BRA(NA) ",
          "stock": 1,
          "secondaryStock": 0,
          "partnerId": 1,
          "stockAreaId": 1,
          "stockAreaName": "Central Stock - Porvorim",
          "grnNumber": "GRN-JUL-2024-25",
          "isPackingItem": false,
          "weight": 1,
          "productTypes": "COMPONENT",
          "assetId": "Fiber Cable - Armoured-8",
          "staffName": " ",
          "isFaulty": false,
          "totalStock": 3
        },
        {
          "stockId": 5663,
          "itemId": 12,
          "itemName": "Fiber Cable - Armoured",
          "productCode": "Fiber Cable - Armoured",
          "price": 14.5,
          "uom": "METER",
          "itemTupleId": 356,
          "shortDescription": "FCC(2F) ,BRA(NA) ",
          "stock": 1,
          "secondaryStock": 0,
          "partnerId": 1,
          "stockAreaId": 1,
          "stockAreaName": "Central Stock - Porvorim",
          "grnNumber": "GRN-JUL-2024-25",
          "isPackingItem": false,
          "weight": 1,
          "productTypes": "COMPONENT",
          "assetId": "Fiber Cable - Armoured-9",
          "staffName": " ",
          "isFaulty": false,
          "totalStock": 4
        }
      ],
      "stockAreaId": 1
    }
  ]
}
