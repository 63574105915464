import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AlertModule, BsDropdownModule, PaginationModule, TooltipModule} from 'ngx-bootstrap';

import {WidgetModule} from '../../layout/widget/widget.module';
import {TextMaskModule} from 'angular2-text-mask';
/* tslint:disable */
import {BootstrapWizardModule} from '../../components/wizard/wizard.module';

/* tslint:enable */
import {SubscriberComponent} from './subscriber/subscriber.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {FranchiesComponent} from './franchies/franchies.component';
import {AreaComponent} from './area/area.component';
import {CountryComponent} from './country/country.component';
import {CityComponent} from './city/city.component';
import {StateComponent} from './state/state.component';

import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {Ng2TableModule} from 'ng2-table';

import {UtilsModule} from '../../layout/utils/utils.module';
import {JqSparklineModule} from '../../components/sparkline/sparkline.module';
import {CustomModalModule} from '../../components/custom-modal';

import {NgSelectModule} from '@ng-select/ng-select';

// import { LoadingComponent } from '../../components/loading/loading.component';
// import { PaginationComponent } from '../../components/pagination/pagination.component';
// import { GridComponent } from '../../components/grid/grid.component';
import {SharedModule} from '../../layout/shared.module';


import {AddFranchiesComponent} from './add-franchies/add-franchies.component';
import {AddAreaComponent} from './add-area/add-area.component';
import {AddCountryComponent} from './add-country/add-country.component';
import {AddStateComponent} from './add-state/add-state.component';
import {AddCityComponent} from './add-city/add-city.component';
import {SubscriberDetailsComponent} from './subscriber-details/subscriber-details.component';
import {AddSubscriberDetailsComponent} from './add-subscriber-details/add-subscriber-details.component';
import {PrimaryInformationComponent} from './add-subscriber-details/primary-information/primary-information.component';
import {AddressInformationComponent} from './add-subscriber-details/address-information/address-information.component';
import {PlanServiceComponent} from './add-subscriber-details/plan-service/plan-service.component';
import {OtherDetailsComponent} from './add-subscriber-details/other-details/other-details.component';
import {ChargesComponent} from './subscriber-details/charges/charges.component';
import {ChangeQosComponent} from './subscriber-details/change-qos/change-qos.component';
import {ChangeExpiryComponent} from './subscriber-details/change-expiry/change-expiry.component';
import {ChangeStatusComponent} from './subscriber-details/change-status/change-status.component';
import {ChangeVoiceDetailsComponent} from './subscriber-details/change-voice-details/change-voice-details.component';

import {ActivePlanComponent} from './subscriber-details/subscriber-tables/active-plan/active-plan.component';
import {FuturePlansComponent} from './subscriber-details/subscriber-tables/future-plans/future-plans.component';
import {PlanHistoryComponent} from './subscriber-details/subscriber-tables/plan-history/plan-history.component';
import {ApplyChargeComponent} from './subscriber-details/apply-charge/apply-charge.component';
import {ReverseChargeComponent} from './subscriber-details/reverse-charge/reverse-charge.component';
import {ChangeQuotaComponent} from './subscriber-details/change-quota/change-quota.component';
import {ResetMacComponent} from './subscriber-details/reset-mac/reset-mac.component';
import {ManageMacComponent} from './subscriber-details/manage-mac/manage-mac.component';
import {ChangePasswordComponent} from './subscriber-details/change-password/change-password.component';
import {ResetPasswordComponent} from './subscriber-details/reset-password/reset-password.component';
import {CpePasswordComponent} from './subscriber-details/cpe-password/cpe-password.component';
import {ResetCpePasswordComponent} from './subscriber-details/reset-cpe-password/reset-cpe-password.component';
import {ContactDetailsComponent} from './subscriber-details/contact-details/contact-details.component';
import {NetworkDetailsComponent} from './subscriber-details/network-details/network-details.component';
import {BasicDetailsComponent} from './subscriber-details/basic-details/basic-details.component';
import {AddressDetailsComponent} from './subscriber-details/address-details/address-details.component';
import {RecordPaymentComponent} from './subscriber-details/record-payment/record-payment.component';
import {ReversePaymentComponent} from './subscriber-details/reverse-payment/reverse-payment.component';
import {UpdatePaymentDetailsComponent} from './subscriber-details/update-payment-details/update-payment-details.component';

import {ChangePlanComponent} from './subscriber-details/change-plan/change-plan.component';

// import {HelperService} from '../layout/helper/helper.service';
import {AddMactalComponent} from './add-subscriber-details/add-mactal/add-mactal.component';
import {CustomerDocumentComponent} from './subscriber-details/customer-document/customer-document.component';
import {PurchaseIpComponent} from './subscriber-details/purchase-ip/purchase-ip.component';
import {AllocationIpComponent} from './subscriber-details/allocation-ip/allocation-ip.component';
import {AllocationIpSelectComponent} from './subscriber-details/allocation-ip-select/allocation-ip-select.component';

import {ReplaceIpAddressComponent} from './subscriber-details/replace-ip-address/replace-ip-address.component';
import {RollbackIpAddressComponent} from './subscriber-details/rollback-ip-address/rollback-ip-address.component';
import {ChangeIpExpiryComponent} from './subscriber-details/change-ip-expiry/change-ip-expiry.component';
import {ReleaseIpAddressComponent} from './subscriber-details/release-ip-address/release-ip-address.component';


import {PubSubModule} from '../../services/pubsub/pubsub.module';

import {CancelPlanComponent} from './subscriber-details/subscriber-tables/cancel-plan/cancel-plan.component';

import {DateAdapter, MatAccordion, MatDatepickerModule, MatExpansionModule,MatTabsModule, MatFormFieldModule, MatIconModule, MatInputModule, MatNativeDateModule,MatTableModule,MatCheckboxModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatButtonModule,
    MatRippleModule,
    MatMenuModule} from '@angular/material';
import {DateFormat} from '../../services/date-format';
import {NetworkSessionComponent} from './subscriber-details/subscriber-tables/network-session/network-session.component';
import {LedgerComponent} from './subscriber-details/subscriber-tables/ledger/ledger.component';
import {PaymentsComponent} from './subscriber-details/subscriber-tables/payments/payments.component';
import {PurchasesComponent} from './subscriber-details/subscriber-tables/purchases/purchases.component';
import {TroubleTicketsDetailsComponent} from './subscriber-details/subscriber-tables/trouble-tickets-details/trouble-tickets-details.component';
import {ChangeOtherDetailsComponent} from './subscriber-details/change-other-details/change-other-details.component';
import {AdjustPaymentDetailsComponent} from './subscriber-details/adjust-payment-details/adjust-payment-details.component';
import {CustomerDocumentDetailsComponent} from './subscriber-details/customer-document/customer-document-details/customer-document-details.component';
import {TestPaymentComponent} from './test-payment/test-payment.component';
import {PaymentStatusComponent} from '../../components/payment-status/payment-status.component';
import {AppGuard} from '../../app.guard';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

import {AclConstants} from '../aclOperationConstants';
import {AclClassConstants} from '../aclClassConstants';
import {ChargesListComponent} from './subscriber-details/subscriber-tables/charges-list/charges-list.component';
import {OtherUpdatesComponent} from './subscriber-details/other-updates/other-updates.component';
import {CommissionByPartnerComponent} from './add-franchies/commission-by-partner/commission-by-partner.component';
import {PartnerLedgerComponent} from './add-franchies/partner-ledger/partner-ledger.component';


import {PartnerPaymentDtlComponent} from './add-franchies/partner-payment-dtl/partner-payment-dtl.component';
import {LocationDetailsComponent} from './subscriber-details/location-details/location-details.component';
import {PincodeListComponent} from './pincode/pincode-list/pincode-list.component';
import {PincodeAddUpdateComponent} from './pincode/pincode-add-update/pincode-add-update.component';
import {PinAreaComponent} from './pin-area/pin-area.component';
import {PinAreaAddUpdateComponent} from './pin-area/pin-area-add-update/pin-area-add-update.component';
import {ValueAddedServicesComponent} from './subscriber-details/subscriber-tables/value-added-services/value-added-services.component';
import {PartnerConfigurationComponent} from './add-franchies/partner-configuration/partner-configuration.component';
import { ProvisionStatusComponent } from './subscriber-details/provision-status/provision-status.component';
import { SubscriberLeasedLineComponent } from './subscriber-leased-line/subscriber-leased-line.component';
import { SubscriberLeasedLineDetailsComponent } from './subscriber-leased-line-details/subscriber-leased-line-details.component';
import { PropertyDetailComponent } from './property-detail/property-detail.component';
import { CircuitDetailsComponent } from './circuit-details/circuit-details.component';
import { LeasedPropertyDetailComponent } from './leased-property-detail/leased-property-detail.component';
import { AddUpdateCircuitComponent } from './add-update-circuit/add-update-circuit.component';
import { ChangePartnerComponent } from './subscriber-details/change-partner/change-partner.component';
import { CommunicationComponent } from './subscriber-details/communication/communication.component';
//import { IpPoolConfigurationComponent } from './add-franchies/ip-pool-configuration/ip-pool-configuration.component';
import { TroubleTicketDetailsComponent } from './subscriber-leased-line-details/trouble-ticket-details/trouble-ticket-details.component';
import { IpPoolConfigurationComponent } from './add-franchies/ip-pool-configuration/ip-pool-configuration.component';
import { IppoolConfigDetailsComponent } from './add-franchies/ippool-config/ippool-config-details/ippool-config-details.component';
import { IppoolConfigComponent } from './add-franchies/ippool-config/ippool-config.component';
import { ManageVlanComponent } from './subscriber-details/manage-vlan/manage-vlan.component';
import { ChangeRepresentativeComponent } from './subscriber-details/change-representative/change-representative.component';
import { GenericContactDetailsComponent } from './generic-contact-details/generic-contact-details.component';
import { CustomerCareModule } from '../customer-care/customer-care.module';
import { PurchaseAddonComponent } from './subscriber-details/purchase-addon/purchase-addon.component';
import { ExtendSuspensionComponent } from './subscriber-details/extend-suspension/extend-suspension.component';

import { AuditComponent } from '../radius/audit/audit.component';
import { RadiusModule } from '../radius/radius.module';
import {SplitButtonModule} from 'primeng/splitbutton';
import { TieredMenuModule } from "primeng/tieredmenu";
import { CasesAddUpdateComponent } from '../customer-care/cases/cases-add-update/cases-add-update.component';
import { PartnerRoleComponent } from './add-franchies/partner-role/partner-role.component';

import { IptvDetailsComponent } from './subscriber-details/iptv-details/iptv-details.component';
import { ChangePaymentCollectorComponent } from './subscriber-details/change-payment-collector/change-payment-collector.component';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';

import { InventoryModule } from '../inventory/inventory/inventory.module';
import { NewSubscriberAddUpdateComponent } from './new-subscriber-add-update/new-subscriber-add-update.component';
import { PurchasePlanComponent } from './subscriber-details/purchase-plan/purchase-plan.component';
import { PurchaseAddonPlanComponent } from './subscriber-details/purchase-addon-plan/purchase-addon-plan.component';
import { NgxPrintModule } from 'ngx-print';
import { AssociatedVasDetailComponent } from './subscriber-details/subscriber-tables/associated-vas-detail/associated-vas-detail.component';
import { PlanActivationComponent } from './subscriber-details/subscriber-tables/plan-activation/plan-activation.component';
import { PropertyDocumentListComponent } from './property-document-list/property-document-list.component';
import { PropertyDocumentUploadComponent } from './property-document-upload/property-document-upload.component';
import { BlockSubscriberComponent } from './subscriber-details/block-subscriber/block-subscriber.component';
import { ChangeUserNameComponent } from './subscriber-details/change-user-name/change-user-name.component';
import { CommentListComponent } from './subscriber-details/comment-details/comment-list/comment-list.component';
import { AddUpdateCommentComponent } from './subscriber-details/comment-details/add-update-comment/add-update-comment.component';
import { UpdateCircuitidComponent } from './circuit-details/update-circuitid/update-circuitid.component';

export const routes = [
    {path: '', redirectTo: '', pathMatch: 'full'}, //Remove the redirectTo:'subscriber' When Default sreen show large
    {
        path: 'subscriber',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_CUSTOMER_VIEW,
            classId: AclClassConstants.ACL_CUSTOMER,
            accessIdForAllOpreation: AclConstants.OPERATION_CUSTOMER_ALL,
            operation: 'subscriber'
        },
        component: SubscriberComponent
    },
    {
        path: 'subscriber/details/:id',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_CUSTOMER_EDIT,
            classId: AclClassConstants.ACL_CUSTOMER,
            accessIdForAllOpreation: AclConstants.OPERATION_CUSTOMER_ALL,
            operation: 'Edit subscriber'
        },
        component: SubscriberDetailsComponent
    },
    {
        path: 'subscriber/details/purchase-plan/:id',
        component: PurchasePlanComponent
    },
    {
        path: 'subscriber/details/purchase-addon-plan/:id',
        component: PurchaseAddonPlanComponent
    },
    {
        path: 'subscriber/details/ledger/:id',
        component: LedgerComponent
    },
    {
        path: 'subscriber/add_subscriber',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_CUSTOMER_ADD,
            classId: AclClassConstants.ACL_CUSTOMER,
            accessIdForAllOpreation: AclConstants.OPERATION_CUSTOMER_ALL,
            operation: 'Add new subscriber'
        },
        component: AddSubscriberDetailsComponent
    },
    {
        path: 'subscriber/quick_add_subscriber',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_CUSTOMER_QUICK_ADD,
            classId: AclClassConstants.ACL_CUSTOMER,
            accessIdForAllOpreation: AclConstants.OPERATION_CUSTOMER_ALL,
            operation: 'Quick add new subscriber'
        },
        component: NewSubscriberAddUpdateComponent
    },
    {
        path: 'franchies',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_PARTNER_VIEW,
            classId: AclClassConstants.ACL_FRANCHISE,
            accessIdForAllOpreation: AclConstants.OPERATION_PARTNER_ALL,
            operation: 'franchies'
        },
        component: FranchiesComponent
    },
    {
        path: 'franchies/add_franchies',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_PARTNER_ADD,
            classId: AclClassConstants.ACL_FRANCHISE,
            accessIdForAllOpreation: AclConstants.OPERATION_PARTNER_ALL,
            operation: 'Add new franchies'
        },
        component: AddFranchiesComponent
    },
    {
        path: 'franchies/add_franchies/:id',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_PARTNER_EDIT,
            classId: AclClassConstants.ACL_FRANCHISE,
            accessIdForAllOpreation: AclConstants.OPERATION_PARTNER_ALL,
            operation: 'Edit franchies'
        },
        component: AddFranchiesComponent
    },
    {
        path: 'area',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_AREA_VIEW,
            classId: AclClassConstants.ACL_AREA,
            accessIdForAllOpreation: AclConstants.OPERATION_AREA_ALL,
            operation: 'area'
        },
        component: PinAreaComponent
    },
    {
        path: 'area/add-area',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_AREA_ADD,
            classId: AclClassConstants.ACL_AREA,
            accessIdForAllOpreation: AclConstants.OPERATION_AREA_ALL,
            operation: 'Add area'
        },
        component: PinAreaAddUpdateComponent
    },
    {
        path: 'area/add-area/:id',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_AREA_EDIT,
            classId: AclClassConstants.ACL_AREA,
            accessIdForAllOpreation: AclConstants.OPERATION_AREA_ALL,
            operation: 'Edit area'
        },
        component: PinAreaAddUpdateComponent
    },
    {
        path: 'country',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_COUNTRY_VIEW,
            classId: AclClassConstants.ACL_COUNTRY,
            accessIdForAllOpreation: AclConstants.OPERATION_COUNTRY_ALL,
            operation: 'country'
        },
        component: CountryComponent
    },
    {
        path: 'country/add_country',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_COUNTRY_ADD,
            classId: AclClassConstants.ACL_COUNTRY,
            accessIdForAllOpreation: AclConstants.OPERATION_COUNTRY_ALL,
            operation: 'Add new country'
        },
        component: AddCountryComponent
    },
    {
        path: 'country/add_country/:id',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_COUNTRY_EDIT,
            classId: AclClassConstants.ACL_COUNTRY,
            accessIdForAllOpreation: AclConstants.OPERATION_COUNTRY_ALL,
            operation: 'Edit country'
        },
        component: AddCountryComponent
    },
    {
        path: 'city',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_CITY_VIEW,
            classId: AclClassConstants.ACL_CITY,
            accessIdForAllOpreation: AclConstants.OPERATION_CITY_ALL,
            operation: 'city'
        },
        component: CityComponent
    },
    {
        path: 'city/add_city',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_CITY_ADD,
            classId: AclClassConstants.ACL_CITY,
            accessIdForAllOpreation: AclConstants.OPERATION_CITY_ALL,
            operation: 'Add New City'
        },
        component: AddCityComponent
    },
    {
        path: 'city/add_city/:id',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_CITY_EDIT,
            classId: AclClassConstants.ACL_CITY,
            accessIdForAllOpreation: AclConstants.OPERATION_CITY_ALL,
            operation: 'Edit City'
        },
        component: AddCityComponent
    },
    {
        path: 'state',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_STATE_VIEW,
            classId: AclClassConstants.ACL_STATE,
            accessIdForAllOpreation: AclConstants.OPERATION_STATE_ALL,
            operation: 'state'
        },
        component: StateComponent
    },
    {
        path: 'state/add_state',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_STATE_ADD,
            classId: AclClassConstants.ACL_STATE,
            accessIdForAllOpreation: AclConstants.OPERATION_STATE_ALL,
            operation: 'Add new state'
        },
        component: AddStateComponent
    },
    {
        path: 'state/add_state/:id',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_STATE_EDIT,
            classId: AclClassConstants.ACL_STATE,
            accessIdForAllOpreation: AclConstants.OPERATION_STATE_ALL,
            operation: 'Edit state'
        },
        component: AddStateComponent
    },
    {
        path: 'pincode',
        component: PincodeListComponent
    },
    {
        path: 'pincode/add_pincode',
        component: PincodeAddUpdateComponent
    },
    {
        path: 'pincode/add_pincode/:id',
        component: PincodeAddUpdateComponent
    },
    {path: 'payment', component: TestPaymentComponent},
    
    {   path: 'suscriberLeased',
         canActivate: [AppGuard],
         data: {
            operationId: AclConstants.OPERATION_SUBSCRIBER_LEASEDLINE_VIEW,
            classId: AclClassConstants.ACL_SUBSCRIBER_LEASED_LINE,
            accessIdForAllOpreation: AclConstants.OPERATION_SUBSCRIBER_LEASEDLINE_ALL,
            operation: 'Lease Line Subscriber'
         },
        component: SubscriberLeasedLineComponent},
    {   path: 'suscriberLeased-details/:id',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_SUBSCRIBER_LEASEDLINE_EDIT,
            classId: AclClassConstants.ACL_SUBSCRIBER_LEASED_LINE,
            accessIdForAllOpreation: AclConstants.OPERATION_SUBSCRIBER_LEASEDLINE_ALL,
            operation: 'Lease Line Subscriber'
        },
     component: SubscriberLeasedLineDetailsComponent},
    {   path: 'suscriberLeased-details',
        canActivate: [AppGuard],
        data: {
            operationId: AclConstants.OPERATION_SUBSCRIBER_LEASEDLINE_VIEW,
            classId: AclClassConstants.ACL_SUBSCRIBER_LEASED_LINE,
            accessIdForAllOpreation: AclConstants.OPERATION_SUBSCRIBER_LEASEDLINE_ALL,
            operation: 'Lease Line Subscriber'
        },
    component: SubscriberLeasedLineDetailsComponent},
    {path: 'subscriberLeased-property-detail/:id', component: PropertyDetailComponent},
    {path: 'subscriberLeased/property-detail/:propertyId', component: PropertyDetailComponent},




  
  
   
];

@NgModule({
    declarations: [
        InvoiceDetailComponent,
        SubscriberComponent,
        FranchiesComponent,
        AreaComponent,
        CountryComponent,
        CityComponent,
        StateComponent,
        AddFranchiesComponent,
        AddAreaComponent,
        SubscriberDetailsComponent,
        AddSubscriberDetailsComponent,
        AddCountryComponent,
        AddStateComponent,
        AddCityComponent,
        PrimaryInformationComponent,
        AddressInformationComponent,
        PlanServiceComponent,
        OtherDetailsComponent,
        ChargesComponent,
        ChangeQosComponent,
        ChangeExpiryComponent,
        ChangeStatusComponent,
        ChangeVoiceDetailsComponent,
        ActivePlanComponent,
        FuturePlansComponent,
        PlanHistoryComponent,
        ApplyChargeComponent,
        ReverseChargeComponent,
        ChangeQuotaComponent,
        ResetMacComponent,
        ManageMacComponent,
        ChangePasswordComponent,
        ResetPasswordComponent,
        CpePasswordComponent,
        ResetCpePasswordComponent,
        ContactDetailsComponent,
        NetworkDetailsComponent,
        BasicDetailsComponent,
        AddressDetailsComponent,
        RecordPaymentComponent,
        ReversePaymentComponent,
        UpdatePaymentDetailsComponent,
        NetworkSessionComponent,
        LedgerComponent,
        AddMactalComponent,
        PaymentsComponent,
        PurchasesComponent,
        TroubleTicketsDetailsComponent,
        CustomerDocumentComponent,
        ChangePlanComponent,
        PurchaseIpComponent,
        AllocationIpComponent,
        ChangeOtherDetailsComponent,
        AdjustPaymentDetailsComponent,
        ReplaceIpAddressComponent,
        RollbackIpAddressComponent,
        ChangeIpExpiryComponent,
        ReleaseIpAddressComponent,
        AllocationIpSelectComponent,
        CustomerDocumentDetailsComponent,
        TestPaymentComponent,
    
        
        ChargesListComponent,
        OtherUpdatesComponent,
        CancelPlanComponent,
        CommissionByPartnerComponent,
        PartnerPaymentDtlComponent,
        LocationDetailsComponent,
        PartnerLedgerComponent,
        PincodeListComponent,
        PincodeAddUpdateComponent,
        PinAreaComponent,
        PinAreaAddUpdateComponent,
        ValueAddedServicesComponent,
        PartnerConfigurationComponent,
        ProvisionStatusComponent,
        SubscriberLeasedLineComponent,
        SubscriberLeasedLineDetailsComponent,
        PropertyDetailComponent,
        CircuitDetailsComponent,
        LeasedPropertyDetailComponent,
        AddUpdateCircuitComponent,
        ChangePartnerComponent,
        CommunicationComponent,
        // IpPoolConfigurationComponent,
        TroubleTicketDetailsComponent,
        ManageVlanComponent,
        IpPoolConfigurationComponent,
        IppoolConfigComponent,
        IppoolConfigDetailsComponent,
        ChangeRepresentativeComponent,
        GenericContactDetailsComponent,
        PurchaseAddonComponent,
        ExtendSuspensionComponent,
        IptvDetailsComponent,
        PartnerRoleComponent,
        ChangePaymentCollectorComponent,
        NewSubscriberAddUpdateComponent,
        PurchasePlanComponent,
        PurchaseAddonPlanComponent,
        AssociatedVasDetailComponent,
        PlanActivationComponent,
        PropertyDocumentUploadComponent,
        PropertyDocumentListComponent,
        BlockSubscriberComponent,
        ChangeUserNameComponent,
        UpdateCircuitidComponent,
        CommentListComponent,
        AddUpdateCommentComponent
       
    ],
    imports: [
        TieredMenuModule,
        SplitButtonModule,
        MatTabsModule,
        SharedModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TextMaskModule,
        TooltipModule.forRoot(),
        AlertModule.forRoot(),
        BsDropdownModule.forRoot(),
        WidgetModule,
        BootstrapWizardModule,
        MatButtonToggleModule,
        RouterModule.forChild(routes),
        EditorModule,
        JqSparklineModule,
        CustomModalModule,
        PaginationModule.forRoot(),
        UtilsModule,
        Ng2TableModule,
        NgxDatatableModule,
        NgSelectModule,
        PubSubModule.forRoot(),
        MatDatepickerModule,
        MatNativeDateModule,
        MatExpansionModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatCheckboxModule,
        MatPaginatorModule,MatSlideToggleModule,
        CustomerCareModule,
        MatTabsModule,
        RadiusModule,
        MatStepperModule,
        InventoryModule,
        MatMenuModule
    ],
    exports: [
        CommissionByPartnerComponent,
        PartnerPaymentDtlComponent,
        PartnerLedgerComponent,
        RecordPaymentComponent,
        PaymentsComponent,
        InvoiceDetailComponent,
        AssociatedVasDetailComponent,
        AddressDetailsComponent
    ],
    providers: [{provide: DateAdapter, useClass: DateFormat}, AppGuard],
})
export class MasterFormsModule {
    constructor(private dateAdapter: DateAdapter<Date>) {
        dateAdapter.setLocale('en-in'); // DD/MM/YYYY
        // Date.prototype.getToJSON = function(){
        //     const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
        //     this.setHours(hoursDiff);
        //     return this.toISOString();
        // };
    } 

    static routes = routes;
}
