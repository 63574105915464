import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MenuItems {
    constructor(){

    }
    public menuItems : any = [
        {
            id:1,
            name:"Masters",
            subClassess:[
                {
                    id:2,
                    name:"Subscriber"
                },
                {
                    id:3,
                    name:"Franchise"
                },
                {
                    id:4,
                    name:"Country"
                },
                {
                    id:5,
                    name:"State"
                },
                {
                    id:6,
                    name:"City"
                }
                ,
                {
                    id:45,
                    name:"Pincode"
                },
                ,
                {
                    id:46,
                    name:"Area"
                },
                { 
                    id:59,
                    name:"Lease Line Subscriber"
                },
                { 
                    id:97,
                    name:"Lead"
                },
             
        
            ]
        },
        {
            id:79,
            name:"Address",
            subClassess:[
                {
                    id:80,
                    name:"Country"
                },
                {
                    id:81,
                    name:"State"
                },
                {
                    id:82,
                    name:"District"
                },
                {
                    id:83,
                    name:"Taluka"
                },
                {
                    id:84,
                    name:"Constituency"
                },
                ,
                {
                    id:85,
                    name:"City"
                },
                { 
                    id:86,
                    name:"Pincode"
                },
                { 
                    id:87,
                    name:"Area"
                },
                { 
                    id:88,
                    name:"Building"
                }
        
            ]
        },
        {
            id:7,
            name:"Radius",
            subClassess:[
                {
                    id:8,
                    name:"Clients"
                },
                {
                    id:9,
                    name:"Client Group"
                },
                {
                    id:10,
                    name:"Subscriber CDRs"
                },
                {
                    id:11,
                    name:"Connected Subscriber"
                },
                {
                    id:12,
                    name:"Accounting Profile"
                },
                {
                    id:13,
                    name:"Policies"
                },
                {
                    id:78,
                    name:'Dynaauth Policies'
                },
                {
                    id:14,
                    name:"Audit"
                }
            ]
        },
        {
            id:15,
            name:"Packages",
            subClassess:[
                {
                    id:16,
                    name:"Packages"
                },
                {
                    id:17,
                    name:"Tax"
                },
                {
                    id:18,
                    name:"Charge"
                },
                {
                    id:19,
                    name:"Discount"
                },
                {
                    id:20,
                    name:"Service"
                },
                {
                    id:21,
                    name:"QOS Policy"
                },
                {
                    id:22,
                    name:"Price Block"
                },
                {
                    id:51,
                    name:"Value Added Services"
                },
                {
                    id:74,
                    name:'Dunning Rules'
                }
            ]
        },  
        {
            id:23,
            name:"Networks",
            subClassess:[
                {
                    id:24,
                    name:"Service Area"
                },
                {
                    id:25,
                    name:"Network Devices"
                },
                {
                    id:26,
                    name:"IP Pool"
                },
                {
                    id:56,
                    name:"Network Status"
                },
                {
                    id:61,
                    name:"Network Search"
                },
                {
                    id:73,
                    name:"Network tree"
                },
                { 
                    id:110,
                    name:"Base Setup"
                }
            ]
        },  
        {
            id:27,
            name:"Customer Care",
            subClassess:[
                {
                    id:28,
                    name:"Cases"
                },
                {
                    id:72,
                    name:'Internal Case'
                },
                {
                    id:29,
                    name:"Case Reason"
                },
                {
                    id:114,
                    name:"RemarkTemplate"
                }
            ]
        },  
        {
            id:30,
            name:"Operations",
            subClassess:[
                {
                    id:34,
                    name:"Broadcast"
                },
                {
                    id:40,
                    name:"Notification"
                },
                {   
                    id:39,
                    name:"AuditLog Report"
                },
                {
                    id:55,
                    name:"Notification Group"   
                },
                {
                    id:68,
                    name:"Billing Template"  
                }
            ]
        },
        {
            id:35,
            name:"Reports",
            subClassess:[
                {
                    id:36,
                    name:"Partner Reports"
                },
                {
                    id:37,
                    name:"Revenue Report"
                }
                ,
                {
                    id:48,
                    name:"Recent Renewal"
                },
                {
                    id:49,
                    name:"Charge"
                },
                {
                    id:50,
                    name:"Purchase History"
                },
                {
                    id:60,
                    name:"Payment Report"
                },
                {
                    id:63,
                    name:"Customer Status Report"
                },
                {
                    id:62,
                    name:"Change Plan Expiry Report"
                },
                {
                    id:64,
                    name:"New Registered Subscriber Report"
                },
                {
                    id:65,
                    name:"Expired Report"
                },
                {
                    id:66,
                    name:"Case Report"
                },
                {
                    id:76,
                    name:"Internal Case Report"
                },
                {
                    id:67,
                    name:"Communication Report"
                },
                {
                   id:91,
                   name:"Lease Line Report" 
                },
                {   
                  id:92,
                  name:"CyberCrime Query"
                },
                {  
                    id:93,
                    name:"Sales Report"
                },
                {  
                    id:95,
                    name:"aadharlog report"
                }
            ]
        },
        {
            id:31,
            name:"HR",
            subClassess:[
                {
                    id:32,
                    name:"ACL"
                },
                {
                    id:33,
                    name:"Staff"
                },
                {
                    id:41,
                    name:"Teams"
                },
                {
                    id:108,
                    name:"Group"
                }
            ]
        },
        {
            id:42,
            name:"Bulk Operations",
            subClassess:[
                {
                    id:43,
                    name:"Bulk Operations"
                },
                {
                    id:69,
                    name:"Bulk-History Report"
                }
            ]
        },
        {  id:53,
           name:"Configuration",
           subClassess:[
            {
                id:54,
                name:"SMS Provider"
            },
            {
                id:90,
                name:"VAS Provider"
            }
           ]
        },
        {   id:70,
            name:"Dashboard",
            subClassess:[
             {
                 id:77,
                 name:"Live User Count Chart"
             }
            ]
         },
         {
            id:5000,
            name:"Inventory Menu",
            subClassess:[
             {
                 id:5001,
                 name:"Inventory"
             }
            ]
         }
    ]

    public getMenuId(menuName){
        let filterPersmissionList = this.menuItems.filter(menu => menu.name === menuName);
        if(filterPersmissionList.length !== 0){
            return filterPersmissionList[0].id;
        }
    }

    public getSubMenuId(menuName,subMenuName){
        let filterPersmissionList = this.menuItems.filter(menu => menu.name === menuName);
        if(filterPersmissionList.length !== 0){
            let submenuList = filterPersmissionList[0].subClassess.filter(subMenu => subMenu.name === subMenuName);
            if(submenuList.length !== 0){
                return submenuList[0].id;
            }
        }
    }

    public getChildSubMenuId(menuName,subMenuName,childSubMenuName){
        let filterPersmissionList = this.menuItems.filter(menu => menu.name === menuName);
        let submenuList = filterPersmissionList[0].subClassess.filter(subMenu => subMenu.name === subMenuName);
        if(submenuList.length !== 0){
            if(submenuList[0].subChildClassess.length !== 0){
                let childSubmenuList = submenuList[0].subChildClassess.filter(childSubMenu => childSubMenu.name === childSubMenuName);
                if(childSubmenuList.length !== 0){
                    return childSubmenuList[0].id;
                }
            }
        }
    }
}
