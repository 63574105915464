export const AclClassConstants = {
    ACL_CITY:1,
    ACL_STATE:2,
    ACL_COUNTRY:3,
    ACL_FRANCHISE:4,
    ACL_CUSTOMER:5,
    ACL_CLIENTS:6,
    ACL_CLIENT_GROUP:7,
    ACL_ACCOUNTING_PROFILE:8,
    ACL_POLICIES:9,
    ACL_AUDIT:10,
    ACL_SUBSCRIBER_CDRS:11,
    ACL_CONNECTED_SUBSCRIBER:12,
    ACL_PACKAGES:13,
    ACL_TAX:14,
    ACL_CHARGE:15,
    ACL_DISCOUNT:16,
    ACL_SERVICE:17,
    ACL_QOS_POLICY:18,
    ACL_PRICE_BOOK:19,
    ACL_SERVICE_AREA:20,
    ACL_NETWORK_DEVICES:21,
    ACL_IP_POOL:22,
    ACL_CASES:23,
    ACL_CASE_REASON:24,
    ACL_STAFF:25,
    ACL_BROADCAST:26,
    ACL_TEMPLATE:27,
    ACL_ACL:28,
    ACL_TEAM:29,
    ACL_NOTIFICATION:30,
    ACL_BULK_UPLOAD:31,
    ACL_BASE_SETUP:101,
    ACL_CASE_COUNT_REPORT:102,

    //ACL_Bulk_Upload_Case:32,
    ACL_Manage_Profile:33,
    //ACL_PARTNER_REPORTS:28,
    //ACL_REVENUE_REPORTS:29,
    //ACL_SUBSCRIBER_REPORT:30,
    //ACL_SYSTEM_REPORT:31
    ACL_PINCODE:35,  
    ACL_AREA:36,
    

    RECENT_RENEWAL_REPORT_VIEW : 37,
    CHARGE_DETAILS_REPORT_VIEW : 38,
    PURCHASE_HISTORY_REPORT : 39,
    ACL_VAS  : 40,
    ACL_NOTIFICATION_GROUP:43,
    ACL_SMS_PROVIDER:42,
    ACL_NETWORK_STATUS:44,
    ACL_NETWORK_SEARCH:51,
    ACL_SUBSCRIBER_LEASED_LINE:49 ,
    ACL_PAYMENT_REPORT:50,
    ACL_CHANGE_EXPIRY_PLAN:52,
    ACL_CUSTOMER_STATUS_REPORT:53,
    ACL_NEW_REGISTATION_SUBSCRIBER_REPORT:54,
    ACL_EXPIRED_REPORT:55,
    ACL_MTTR_REPORT:57,
    ACL_COMMUNICATION_REPORT:59,
    ACL_AUDIT_LOG:60,
    ACL_DASHBOARD_COUNT:63,
    ACL_BULK_HISTORY:62,
    ACL_NETWORK_TREE:65,
    ACL_INTERNALCASE:64,
    ACL_INTERNALCASE_REPORT :68,
    ACL_DUNNING:66,
    ACL_LIVE_DASHBOARD:69,
    ACL_DYNAAUTH_POLICIES:70,
    ACL_ADDRESS:71,
    ACL_VAS_PROVIDER:73,

    ACL_INVENTORY:5000,
    ACL_LEASELINE_REPORT:74,
    ACL_CLASS_CYBER_CRIME_REPORT:75,
    ACL_SALES_REPORT:76,
    ACL_FIBER_REPORT:78,
    ACL_PAYMENTCOLLECTION_REPORT:77,
    ACL_AADHAR_REPORT:79,
    ACL_INVOICE_REPORT:80,
    ACL_LEAD: 81,
    ACL_SHIPMENT:83,
    ACL_PARTNER_LEAD:85,
    ACL_PARTNER_SUPPORT:87,
    ACL_CUSTOMER_LEAD_REPORT:84,
    ACL_PARTNER_LEAD_REPORT:88,
    ACL_PARTNER_SETTLEMENT_REPORT:89,
    ACL_GROUP:90,
    ACL_PERFORMANCE_REPORT:91,
    ACL_DATA_CONSUMPTION:96,
    ACL_REMARKTEMPLATE:97
}
